import { Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'translation',
  standalone: true,
})
export class TranslationPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: string, context: string = '', ...args: any[]): any {
    const key = context + '.' + value;

    if (!value) {
      return value;
    }

    // Fetch the translation
    const translatedValue = this.translateService.instant(key, ...args);

    // Return the translation if found, otherwise return the original key
    return translatedValue === key ? value : translatedValue;
  }
}
